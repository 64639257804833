<template>
    <div class="vx-row ml-1 mt-3">
        <div class="vx-row mb-6" style="width:70%">
            <div class="vx-col sm:w-1/4 w-full flex items-center">
                <span>Supplier</span>
            </div>
            <div class="vx-col sm:w-3/4 w-full">
                <Supplier 
                    placeholder="Select supplier / vendor"
                    @selected="(val) => {
                        this.selectedSupplier = val
                    }"
                ></Supplier>
            </div>
        </div>
        <div class="vx-row mb-1" style="width:70%">
            <div class="vx-col sm:w-1/4 w-full flex items-center">
            </div>
            <div class="vx-col sm:w-3/4 w-full">
                <vs-button color="primary" @click="handleFilter()">Filter</vs-button>
            </div>
        </div>
    </div>
</template>
<script>
import Supplier from "@/components/Supplier.vue";
export default {
    components: {
        Supplier,
    },
    data() {
        return {
            draw: 0,
            selectedSupplier: null,
        }
    },
    methods: {
        handleFilter() {
            this.draw++;
            this.emitData()
        },
        emitData() {
            this.$emit("data", {
                draw: this.draw,
                supplierId: this.selectedSupplier.id
            });
        },
    },
    watch: {
    },
}
</script>