<template>
    <div>
        <div class="vx-row mt-3">
            <div class="vx-col w-full mb-base">
                <FilterData
                    @data="(val) => {
                        this.supplierId = val.supplierId
                        this.draw = val.draw
                    }"
                ></FilterData>
            </div>
        </div>
        <div class="mt-3 vx-row">
            <div class="w-full vx-col mb-base">
                <Table :draw="draw" :status="activeTab"
                :supplierId="(this.supplierId) ? this.supplierId : 0"
                    >
                </Table>
            </div>
        </div>
    </div>
</template>
<script>

import CustomMSelect from "./components/CustomMultiSelect.vue";
import Date from "./components/Date.vue";
import Table from "./components/ApprovedTable.vue";
import FilterData from "./components/FilterData.vue";
export default {
    components: {
        CustomMSelect,
        Date,
        FilterData,
        Table
    },
    data() {
        return {
            activeTab: 1,
            draw: 0,
            supplierId: null
        }
    },
    methods: {
    }
}
</script>